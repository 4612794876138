import React from "react";

const DescriptionCard = ({image, description, alt}) => {
    return(
        <div className="col s12 m6 l6">
            <br/>
            <div className="card hoverable z-depth-3 blue-grey lighten-2 ">
                <div className="card-image"> 
                    <img src= {image} alt = {alt}/>
                </div>
                <div className="card-content">
                    <p className='flow-text white-text'>
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )}

export default DescriptionCard;