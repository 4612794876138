import React, { useState,useEffect} from "react";
import { Link } from "react-router-dom";
import M from "materialize-css";
import logo from '../assets/Logo2.png';
import './navbar.css';

const NavBar = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        // Add a scroll event listener to track scroll position
        const handleScroll = () => {
          if (window.scrollY > 0) 
          {
            setIsScrolled(true);
          } 
          else 
          {
            setIsScrolled(false);
          }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []); // Empty dependency array ensures this runs only once after mount


    useEffect(() => {
        // Initialize the dropdown when the component is mounted
        const dropdownElement = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(dropdownElement, {
            hover:'true'
        });
      }, []); // Empty dependency array ensures this runs only once after mount

    return (
    <>
        <nav className={`navbar ${isScrolled ? 'scrolled' : ''} `}>
            <div className="nav-wrapper navbar-wrapper grey lighten-3">
                <Link to="/" className={`btn-floating ${isScrolled ? 'btn' : 'btn-large'} material-icons white-text home-button`}>home</Link>
                <div className="company-logo">
                 <img src={logo} alt= "logo" width={400} height={150} ></img>
                </div>
                <ul className="right hide-on-med-and-down">
                    <li><Link to="/" className="black-text navbar-text">Notre Société</Link></li>
                    {/* <!-- Dropdown Trigger --> */}
                    <li><a className="dropdown-trigger black-text navbar-text" href="#!" data-target="dropdown1">
                    Nos réalisations<i className="material-icons right teal-text">arrow_drop_down</i></a></li>
                    <li><Link to="/contact" className="black-text navbar-text" >Nous Contacter</Link></li>
                </ul>
            </div>
        </nav>
        {/* {<!-- Dropdown Structure -->} */}
        <ul id="dropdown1" className="dropdown-content" >
            <li><Link to="/maconnerie" className="black-text">Travaux de maçonnerie</Link></li>
            <li><Link to="/charpente-et-couvertures" className="black-text">Charpente et Couvertures</Link></li>
            <li><Link to="/agrandissement" className="black-text">Agrandissements</Link></li>
        </ul>
    </>)}

export default NavBar;