import React from "react";
import rock1 from '../assets/masonry/1.jpg';
import rock2 from '../assets/masonry/2.jpg';
import rock3 from '../assets/masonry/3.jpg';
import rock4 from '../assets/masonry/4.jpg';
import brick1  from '../assets/masonry/5.jpg';
import brick2  from '../assets/masonry/6.jpg';
import other1  from '../assets/masonry/7.jpg';
import other2  from '../assets/masonry/8.jpg';
import other3  from '../assets/masonry/9.jpg';
import other4  from '../assets/masonry/10.jpg';
import other5  from '../assets/masonry/11.jpg';
import other6  from '../assets/masonry/12.jpg';
import DescriptionCard from "../components/description-card";
import { useNavigate } from "react-router-dom";

const Masonry = () => {

    const navigate = useNavigate();

    const rockTab = [
        {
            image: rock1,
            description : "Construction d'une maison cévenole",
            alt : "photo1"
        },
        {
            image: rock2,
            description : "Parement en pierres",
            alt : "photo2"
        },
        {
            image: rock3,
            description : "Jointoiement",
            alt : "photo3"
        },
        {
            image: rock4,
            description : "Tour en pierres",
            alt : "photo4"
        }
    ];

    const brickTab = [
        {
            image: brick1,
            description : "Réalisation en briques",
            alt : "photo5"
        },
        {
            image: brick2,
            description : "Réalisation en briques",
            alt : "photo6"
        }
    ]

    const otherTab = [
        {
            image: other2,
            description : "Dallage schiste",
            alt : "photo9"
        },
        {
            image: other4,
            description : "Escalier en colimaçon",
            alt : "photo11"
        },
        {
            image: other5,
            description : "Drain d'assainissement",
            alt : "photo12"
        },
        {
            image: other6,
            description : "Pose d'une fosse septique",
            alt : "photo13"
        },
        {
            image: other3,
            description : "Béton décoratif",
            alt : "photo10"
        },
        {
            image: other1,
            description : "Construction neuve dans un style moderne",
            alt : "photo7"
        }
    ]

    return(
        <main style={{backgroundColor: '#3a3a3a'}}>
        <br/>
        <div className="container">
            <div className="row">
                <div className="col s12 m12 l12" style={{textAlign: 'center'}}>
                    <br/><br/><br/><br/><br/><br/>
                    <span className="flow-text white-text"> Réalisation de bâtiments en pierre</span>
                </div>
                 {rockTab.map(card => ( <DescriptionCard image={card.image} description={card.description} alt={card.alt}/>))}
                <div className="col s12 m12 l12" style={{textAlign: 'center'}}>
                    <br/><br/><br/><br/><br/><br/>
                    <span className="flow-text white-text"> Réalisation de bâtiments en brique</span>
                </div>
                {brickTab.map(card => ( <DescriptionCard image={card.image} description={card.description} alt={card.alt}/>))}
                <div className="col s12 m12 l12" style={{textAlign: 'center'}}>
                    <br/><br/><br/><br/><br/><br/>
                    <span className="flow-text white-text"> Autres réalisations</span>
                </div>
                {otherTab.map(card => (<DescriptionCard image={card.image} description={card.description} alt={card.alt}/>))}
            </div>
        </div>
        <br/><br/><br/>
        <div className="center-align">
            <button className="btn waves-effect waves-light" onClick={() => { navigate('/contact') }}>
                Nous Contacter
            </button>
        </div>
        <br/><br/><br/>
        </main>)}

export default Masonry;