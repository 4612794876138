import React from "react";
import DescriptionCard from "../components/description-card";
import framework from "../assets/framework-and-tiles/1.jpg";
import tiles from "../assets/framework-and-tiles/2.jpg";
import coveredTerrace from "../assets/framework-and-tiles/3.jpg";
import { useNavigate } from "react-router-dom";

const FrameworkAndTiles = () => {

    const navigate = useNavigate();

    const cardTab = [
        {
            image : framework,
            description : "Charpente de la tour en pierre",
            alt : "charpente"
        },
        {
            image : tiles,
            description : "Pose de tuiles",
            alt : "tuiles"
        },
        {
            image : coveredTerrace,
            description : "Terrasse Couverte",
            alt : "terrasseCouverte"
        }
    ]

    return(
        <main style={{backgroundColor: '#3a3a3a'}}>
            <br/>
            <div className="container">
                <div className="row">
                    <div style={{textAlign: 'center'}}>
                        <br/><br/><br/><br/><br/><br/>
                        <span className="flow-text white-text"> Réalisation d'une charpente et d'une toiture en tuiles</span>
                    </div>
                    {cardTab.map(card => (<DescriptionCard image={card.image} description={card.description} alt={card.alt}/>))}
                </div>
            </div>
            <br/><br/>
            <div className="center-align">
              <button className="btn waves-effect waves-light" onClick={() => { navigate('/contact') }}>
                Nous Contacter
              </button>
            </div>
            <br/><br/><br/>
        </main>
    )}

export default FrameworkAndTiles;