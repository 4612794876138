import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './home-page.css';
import maconnerieImage from '../assets/masonry/1.jpg';
import roofImage from '../assets/framework-and-tiles/2.jpg';
import enlargementImage from '../assets/enlargement/1.jpg';
import mainPageImage from '../assets/main-page/1.jpg';

const spanStyle = {
  padding: '20px',
  color: '#ffffff',
  fontFamily: 'Cambria'
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '700px'
}

const buttonStyle = {
  width: "60px",
  background: 'none',
  border: '0px',
  fontSize: '50px',
  color: 'white'
};

const slideProperties = {
  prevArrow: <button className= "material-icons" style={{ ...buttonStyle }}>chevron_left</button>,
  nextArrow: <button className= "material-icons" style={{ ...buttonStyle }}>chevron_right</button>
}

const slideImages = [
  {
    url: maconnerieImage,
    caption: 'Travaux de maçonnerie',
    redirection: '/maconnerie'
  },
  {
    url: roofImage,
    caption: 'Charpente et Couvertures',
    redirection: '/charpente-et-couvertures'
  },
  {
    url: enlargementImage,
    caption: 'Agrandissements',
    redirection: '/agrandissement'
  },
];

const HomePage = () => {

  const navigate = useNavigate();

    return (
      <div style={{backgroundColor: '#3a3a3a'}}>
        {/* {page slider} */}
       <div className="slide-container slide-offset">
         <Slide duration={3000} {...slideProperties}>
          {slideImages.map((slideImage, index)=> (
             <div key={index}>
               <div style={{ ...divStyle, backgroundImage: `url(${slideImage.url})` }}>
                <div className='button-offset' style={{ textAlign: 'center' }}>
                   <i className='flow-text custom-font-size' style={spanStyle}>{slideImage.caption}</i>
                   <br /><br /><br /> {/* Add line breaks to move the button to the next line */}
                   <button className="btn waves-effect waves-light " onClick={() => {navigate(slideImage.redirection)}}>Voir nos Réalisations</button>
                 </div>
               </div>
             </div>
           ))} 
         </Slide>
       </div>
        {/* {main page} */}
        <br/><br/><br/><br/>
       <main className='container'>
        <div className='row'>
          <div className='col s12 m12 l12'>
            <div className="card horizontal z-depth-3 grey lighten-5  ">
              <div className="card-image"> 
                <img src= {mainPageImage} alt = 'mainPagePhoto'/>
              </div>
              <div className="card-content">
                <p className='flow-text'>
                À votre service depuis plus de 30 ans, l'entreprise Serge Maurin vous propose de réaliser des travaux en neuf ou en rénovation : <br/><br/>
                gros oeuvre maison individuelle , couverture, façades en pierres ,parement ,dallage extérieur, assainissement individuel. <br/><br/> 
                Devis gratuit.
                </p>
              </div>
            </div>
            <br/>
            <div className="center-align">
              <button className="btn waves-effect waves-light" onClick={() => { navigate('/mentions-legales') }}>
                Mentions Légales
              </button>
            </div>
            <br/>
          </div>
        </div>
       </main>
       <br/>
      </div>
    )
}

export default HomePage;