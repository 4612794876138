import React from "react";
import contactImage from "../assets/masonry/5.jpg"

const Contact = () => {
    return (
        <main style={{backgroundColor: '#3a3a3a'}}>
        <br/><br/><br/><br/><br/><br/>
            <div className="container">
                <div className="row">
                    <div className="col s12 m12 l12">
                        <br/><br/><br/><br/><br/>
                        <div className="card horizontal hoverable">
                            <div className="card-image">
                                <img src={contactImage} alt="contactImage"/>
                            </div>
                            <div className="card-content flow-text">
                                <span><strong>Informations</strong></span><br/><br/>
                                <p>
                                    Constructions Serge Maurin intervient dans le département du Gard pour tous travaux.
                                </p> <br/>
                                <p>
                                    Entreprise Maurin <br/>
                                    2503 Route d'Uzès <br/>
                                    30340 Méjannes les Alès <br/>
                                    Tél. fixe : 04 66 61 03 62 <br/>
                                    Tél. portable : 06 81 61 09 09 <br/>
                                    Mail : <a href = "mailto: ent.maurin@wanadoo.fr">ent.maurin@wanadoo.fr</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </main>
)}

export default Contact;