import React from "react";
import enlargement1 from "../assets/enlargement/1.jpg";
import enlargement2 from "../assets/enlargement/2.jpg";
import DescriptionCard from "../components/description-card";
import { useNavigate } from "react-router-dom";

const Enlargement = () => {
    const navigate = useNavigate();

    const enlargementTab = [
        {
            image : enlargement1,
            description : "Agrandissement d'une maison",
            alt : "agrandissement1"
        },
        {
            image : enlargement2,
            description : "Petit agrandissement sur maison ancienne",
            alt : "agrandissement2"
        }
    ]

    return(
        
        <main style={{backgroundColor: '#3a3a3a'}}>
            <br/>
            <div className="container">
                <div className="row">
                    <div className="col s6 m12 l12" style={{textAlign:'center'}}>
                        <br/><br/><br/><br/><br/><br/>
                        <span className="flow-text white-text"> Agrandissements </span>
                    </div>
                    {enlargementTab.map(card => (<DescriptionCard image={card.image} description={card.description} alt={card.alt}/>))}
                </div>
            </div>
            <br/><br/>
            <div className="center-align">
              <button className="btn waves-effect waves-light" onClick={() => { navigate('/contact') }}>
                Nous Contacter
              </button>
            </div>
            <br/><br/><br/><br/>
        </main>
    )}

    export default Enlargement;