import React from 'react';
import { Link } from 'react-router-dom';
  
const PageNotFound = () => {
  
  return (
    <main className="center">
      <br/><br/><br/><br/><br/>
      <img src="https://www.direct-signaletique.com/I-Grande-25641-panneau-stop-r5.net.jpg" alt="Page non trouvée"/>
      <h1>Erreur 404 : Cette Page N'existe pas.</h1> 
      <Link to="/" className="waves-effect waves-teal btn-flat">
        Retourner à l'accueil
      </Link>
    </main>
  );
}
  
export default PageNotFound;