import React from "react";

const LegalMentions = () => {
    return (
        <main style={{backgroundColor: '#3a3a3a'}}>
        <br/><br/><br/><br/><br/><br/>
            <div className="container">
                <div className="row">
                    <div className="col s12 m12 l12">
                        <br/><br/>
                        <div className="card horizontal hoverable">
                            <div className="card-content flow-text">
                                <div className="center-align"><span className="center-align"><strong>Mentions Légales & Politique de Confidentialité </strong></span></div><br/><br/>
                                <p>
                                <strong>Editeur du site internet:</strong> <br/><br/>
                                    EURL CONSTRUCTIONS SERGE MAURIN <br/><br/>

                                    2503 Route d'Uzès 30340 MEJANNES LES ALES <br/><br/>

                                    Tél : 04 66 61 03 62  <br/><br/>

                                    Capital de 30000,00 € <br/> <br/>

                                    Siret : 48273821800015 <br/> <br/>

                                    <strong>Hébergeur: </strong><br/><br/>

                                    Le site internet https://constructions-maurin.fr est hébergé par la société HOSTINGER INTERNATIONAL LTD. dont le siège social est situé au 61 Lordou Vironos Street, 6023 Larnaca, Chypre. <br/>
                                    Le site est hébergé sur un serveur situé en France fourni par HOSTINGER, dans leur Datacenter de Strasbourg. <br/>

                                    Vous pouvez contacter notre hébergeur par mail à l'adresse suivante fr@hostinger.com, ou en vous rendant directement sur leur site internet : https://www.hostinger.fr/contact .<br/><br/>

                                    <strong>Propriété intellectuelle:</strong><br/><br/>

                                    Le SITE dans son ensemble, ainsi que chacun des éléments qui le composent (notamment, textes, schémas, arborescences, logiciels, animations, images, photographies, illustrations, vidéos, représentations graphiques ou éditoriales, logos, etc.) constituent des œuvres de l'esprit protégés par le Code de la propriété intellectuelle ainsi que par toute autre législation internationale applicable.<br/><br/>

                                    EURL CONSTRUCTIONS SERGE MAURIN est seul habilité (directement ou par l'effet de licences ou autorisations) à utiliser les éléments composant le SITE, les noms de domaine, les marques, modèles, etc. ainsi que les images et contenus éditoriaux qui y sont reproduits. <br/>
                                    L'usage de tout ou partie du SITE, notamment par téléchargement, reproduction, transmission, représentation ou diffusion à d'autres fins que la consultation et l'utilisation du SITE à titre personnel et privé par l'internaute dans un but non commercial est strictement interdit. <br/>
                                    La violation de ces dispositions expose son auteur aux sanctions prévues tant par le Code de la propriété intellectuelle au titre notamment de la contrefaçon de droits d'auteur, de droit de marques que par le Code civil en matière de responsabilité civile. La création de tout lien hypertexte renvoyant à l'une quelconque des pages web ou éléments composant le SITE est interdite sauf autorisation écrite et préalable de EURL CONSTRUCTIONS SERGE MAURIN, laquelle autorisation pouvant être révoquée à tout moment.<br/><br/>

                                    Tout contenu,du site internet ou autre ayant un lien hypertexte dirigeant vers le SITE ou vers l'un quelconque des éléments qui le composent ne sont pas sous le contrôle de EURL CONSTRUCTIONS SERGE MAURIN et celle-ci décline par conséquent toute responsabilité (notamment, éditoriale) concernant l'accès à ces sites et leur contenu.<br/><br/>

                                    <strong>Politique de confidentialité:</strong><br/><br/>

                                EURL CONSTRUCTIONS SERGE MAURIN s'engage à ce que la collecte et le traitement de vos données, effectués à partir du site https://constructions-maurin.fr, soient conformes au règlement général sur la protection des données (RGPD) et à la loi Informatique et Libertés.<br/><br/>

                                <strong>Collecte de données personnelles:</strong><br/><br/>

                                Chaque formulaire limite la collecte des données personnelles au strict nécessaire (minimisation des données).<br/><br/>

                                les données recueillies sont utilisées uniquement pour permettre de vous recontacter dans le cadre de la relation commerciale<br/>
                                Les champs précisent clairement si les données demandées sont obligatoires ou facultatives pour la gestion de votre demande<br/>
                                EURL CONSTRUCTIONS SERGE MAURIN est seul à prendre connaissance de ces données et aucune transmission à un tiers n'est nécessaire à la gestion de votre demande.<br/>
                                Vos données personnelles sont conservées dans notre fichier client. Vous pouvez en demander la suppression à tous moments.<br/>
                                Les données personnelles recueillies dans le cadre des services proposés sur https://constructions-maurin.fr sont traitées selon des protocoles sécurisés.<br/>

                                Pour toute information ou exercice de vos droits Informatique et Libertés sur les traitements de données personnelles, vous pouvez nous contacter par courrier signé accompagné de la copie d'un titre d'identité à l'adresse suivante : EURL CONSTRUCTIONS SERGE MAURIN 2503 Route d'Uzès 30340 MEJANNES LES ALES.<br/>

                                <strong>Cookies:</strong><br/><br/>

                                En règle générale, nous utilisons les cookies à fins d'améliorer et de personnaliser le site et/ou en mesurer l'audience. Les cookies sont des fichiers enregistrés sur le disque dur de votre ordinateur lors de votre navigation sur Internet et notamment sur le site. <br/>
                                Un cookie ne sert pas à recueillir des données personnelles à votre insu mais à enregistrer des informations relatives à votre navigation sur le site qui pourront être directement lues par nos services techniques lors de vos visites et requêtes ultérieures sur le site.<br/><br/>
                                Si vous choisissez de refuser l'ensemble des cookies, votre navigation pourra être réduite pour accéder à certaines pages du site. 
                                Les cookies que nous utilisons ont pour finalité de permettre ou faciliter la communication, de permettre la fourniture des services demandés par les utilisateurs, de reconnaître les utilisateurs lors d'une prochaine visite, de sécuriser les paiements que les utilisateurs peuvent être amenés à réaliser, d'enregistrer la langue parlée par les utilisateurs ou autres préférences nécessaires à la fourniture du service demandé.<br/><br/>
                                Nous utilisons aussi des outils d'analyse et de suivi afin de mesurer les données sur les sites Web et les données numériques pour d'obtenir un aperçu de la clientèle, réaliser des analyses sur l'expérience de navigation afin d'améliorer le contenu et d'envoyer des publicités ciblées.<br/><br/>
                                Par défaut, les cookies ne sont pas automatiquement installés (à l'exception des cookies nécessaires au fonctionnement du site et des services de notre site internet dont l'installation vous est signalée par un bandeau). 
                                Conformément à la réglementation applicable, nous requerrerons votre autorisation avant d'implanter tout autre type de cookies sur votre disque dur. Afin d'éviter toute gêne occasionnée par ces demandes d'autorisation systématiques et profiter d'une navigation ininterrompue, vous pouvez configurer votre ordinateur afin d'accepter les cookies de notre site ou nous pouvons mémoriser votre refus ou acceptation relatif à certains cookies. Par défaut les navigateurs acceptent tous les cookies.<br/><br/>
                                Lorsque vous accédez à des sites tiers faisant de la publicité sur notre site, ou éventuellement lorsque vous visualisez ces publicités, des cookies peuvent être créés par les sociétés diffusant ces publicités. Ces tiers pouvant utiliser des cookies dans le cadre de nos services (partenaires ou autres tiers fournissant du contenu) sont responsables des cookies qu'ils mettent en place et ce sont leurs dispositions relatives aux cookies qui s'appliquent. Nous n'assumons aucune responsabilité quant à l'éventuelle utilisation de cookies par ces tiers. <br/>
                                Pour plus de précisions, il vous est conseillé de consulter directement sur ces sites annonceurs ou tiers leur politique en matière d'utilisation de cookies.<br/><br/>

                                <strong>Demande de suppression ou de modification des données personnelles:</strong><br/><br/>

                                Conformément au règlement européen (RGPD), je peux demander la suppression de mes données personnelles dans votre système d'information via courrier signé accompagné de la copie d'un titre d'identité à l'adresse suivante : EURL CONSTRUCTIONS SERGE MAURIN 2503 Route d'Uzès 30340 MEJANNES LES ALES. <br/><br/>
                                </p> <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </main>
)}

export default LegalMentions;