import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageNotFound from './pages/page-not-found';
import HomePage from './pages/home-page';
import NavBar from './components/navbar';
import Contact from './pages/contact';
import Masonry from './pages/masonry';
import FrameworkAndTiles from './pages/framework-and-tiles';
import Enlargement from './pages/enlargement';
import LegalMentions from './pages/legal-mentions';

const App = () => {
    return (
        <div>
            {/* La barre de navigation commun à toutes les pages */}
            <NavBar/>
            {/* Le système de gestion des routes de notre application */}
            <Routes>
                <Route exact path="/" Component={HomePage}/>
                <Route exact path="/home" Component={HomePage}/>
                <Route exact path="/contact" Component={Contact}/>
                <Route exact path="/mentions-legales" Component={LegalMentions}/>
                <Route exact path="/maconnerie" Component={Masonry}/>
                <Route exact path="/charpente-et-couvertures" Component={FrameworkAndTiles}/>
                <Route exact path="/agrandissement" Component={Enlargement}/>
                <Route path="*" Component={PageNotFound}/>
            </Routes>
            <footer className="page-footer teal" style={{zIndex: '1000', position: 'fixed',bottom: '0px', height: 'auto', padding: '10px'}}>
                <i >© 2023 Copyright - EURL Constructions Serge Maurin</i>
            </footer>
        </div>
    )}
  
export default App;